import { format } from "date-fns";
import { buffer, concat, filter, fromPromise, interval, map, merge, pipe, Source } from "wonka";
import { Override } from "../types";
import { dateToStr, MILLISECONDS_PER_MINUTE, strToDate } from "../utils/dates";
import { hashInArray } from "../utils/dev";
import { removeEmpty } from "../utils/objects";
import { deserialize, upsert } from "../utils/wonka";
import { SubscriptionType } from "./adaptors/ws";
import {
  AssistCompleted,
  CalendarEvent as CalendarEventDto,
  Event as EventDto,
  EventColor as EventColorDto,
  EventResponseStatus as EventResponseStatusDto,
  EventType as EventTypeDto,
  ProjectInclude as ProjectIncludeDto,
} from "./client";
import {
  AssistDetails,
  AssistStatus,
  AssistType,
  Category,
  EventColor,
  EventColorStr,
  PrimaryCategory,
} from "./EventMetaTypes";
import { dtoToProject, IncludeProject, Project, projectToDto, Smurf } from "./Projects";
import { NotificationKeyStatus, TransformDomain } from "./types";

export enum EventResponseStatus {
  None = "None",
  Organizer = "Organizer",
  Accepted = "Accepted",
  Declined = "Declined",
  TentativelyAccepted = "TentativelyAccepted",
  NotResponded = "NotResponded",
}

export type EventResponseStatusStr = `${EventResponseStatusDto}`;

export enum ReclaimEventType {
  User = "USER",
  Sync = "SYNC",
  HabitAssignment = "HABIT_ASSIGNMENT",
  OneOnOneAssignment = "ONE_ON_ONE_ASSIGNMENT",
  TaskAssignment = "TASK_ASSIGNMENT",
  SchedulingLinkAssignment = "SCHEDULING_LINK_ASSIGNMENT",
  ConfBuffer = "CONF_BUFFER",
  TravelBuffer = "TRAVEL_BUFFER",
  Unknown = "UNKNOWN",
}

export enum EventStatus {
  Draft = "DRAFT",
  Published = "PUBLISHED",
  Cancelled = "CANCELLED",
  Rescheduling = "RESCHEDULING",
}

export type EventKey = string;

export { SnoozeOption } from "./client";
export interface EventListOptions {
  start?: Date;
  end?: Date;
  type?: PrimaryCategory[];
  calendarIds?: number[];
  include?: IncludeProject;
  sourceDetails?: boolean;
  habitIds?: number[];
  oneOnOneIds?: number[];
  schedulingLinkIds?: string[];
}

export interface EventQueryOptions extends EventListOptions {
  statuses?: EventStatus[];
}

export interface WatchQueryOptions extends EventQueryOptions {}
export interface ListAndWatchOptions extends EventQueryOptions {}

export type CalendarEvent = Readonly<
  Override<
    CalendarEventDto,
    {
      eventId: string;
      title: string;
      startTime: Date;
      endTime: Date;
      color: EventColorStr;
      rsvpStatus: EventResponseStatusStr;
      displayColorHex: string;
    }
  >
>;

export const calendarEventToDto = (data: CalendarEvent): CalendarEventDto => ({
  ...data,
  startTime: data.startTime.toISOString(),
  endTime: data.endTime.toISOString(),
  color: EventColorDto[data.color],
});

export const dtoToCalendarEvent = (dto: CalendarEventDto): CalendarEvent => {
  const { eventId, title, startTime, endTime } = dto;
  if (typeof eventId !== "string") throw new Error("CalendarEvent must have an eventId");
  if (typeof startTime !== "string") throw new Error("CalendarEvent must have an startTime");
  if (typeof endTime !== "string") throw new Error("CalendarEvent must have an endTime");

  const startTimeDate = new Date(startTime);
  const endTimeDate = new Date(endTime);

  if (isNaN(startTimeDate.getTime())) throw new Error("startTime was not a valid ISO date");
  if (isNaN(endTimeDate.getTime())) throw new Error("endTime was not a valid ISO date");

  return {
    ...dto,
    title: title || "",
    eventId,
    startTime: startTimeDate,
    endTime: endTimeDate,
    color: EventColor.get(dto.color).key,
    rsvpStatus: hashInArray(dto.eventId, [
      EventResponseStatus.Accepted,
      EventResponseStatus.Declined,
      EventResponseStatus.None,
      EventResponseStatus.NotResponded,
      EventResponseStatus.Organizer,
      EventResponseStatus.TentativelyAccepted,
    ]),
    // I believe this should always be defined
    displayColorHex: dto.displayColorHex as string,
  };
};

export type Event = Override<
  EventDto,
  {
    // primary key: *THE* PK for an event is: `event.key`
    readonly key: EventKey;
    // deprecated: `event.id` is legacy garbage and should not be used
    readonly id: string;
    readonly reclaimEventType: ReclaimEventType;
    readonly status?: EventStatus;
    readonly category: Category;
    readonly projects?: Project[];
    readonly assist?: AssistDetails;

    eventId: string;
    eventStart: Date;
    eventEnd: Date;
    updated?: Date;
    type?: null;
    subType?: null;
    meetingType?: null;
    // categoryOverride is a write only field to set an overide... use this ONLY to know if the category has been overridden
    categoryOverride?: Category;
    color: EventColor;
    chunks: number;
    smurf?: Smurf;
  }
>;

// TODO: CalendarEvent is an eventual replacement for Event.  This function is to bridge the gap -SG
export const calendarEventToEvent = (calendarEvent: CalendarEvent): Event => ({
  ...calendarEvent,
  category: PrimaryCategory.SoloWork,
  chunks: Math.round(
    (calendarEvent.endTime.getTime() - calendarEvent.startTime.getTime()) / (MILLISECONDS_PER_MINUTE * 15)
  ),
  id: calendarEvent.eventId,
  key: calendarEvent.eventId,
  reclaimEventType: ReclaimEventType.Unknown,
  rsvpStatus: EventResponseStatus.Accepted,
  eventStart: calendarEvent.startTime,
  eventEnd: calendarEvent.endTime,
  color: EventColor.get(calendarEvent.color),
});

const eventsQueryFilter = (options: EventQueryOptions): ((events: Event[]) => Event[]) => {
  const { statuses, start, end, habitIds, type, oneOnOneIds, calendarIds } = options;
  return (events: Event[]) => {
    return events
      .filter(
        (e) =>
          (!start || !(e.eventStart <= start && e.eventEnd <= start)) &&
          (!end || !(e.eventStart > end && e.eventEnd > end))
      )
      .filter((e) => !calendarIds || (!!e.calendarId && calendarIds.includes(e.calendarId)))
      .filter((e) => !!e.status && !!statuses && statuses.includes(e.status))
      .filter((e) => !type || (!!e.type && type.includes(e.type)))
      .filter((e) => !habitIds || (!!e.assist?.dailyHabitId && habitIds?.includes(e.assist.dailyHabitId)))
      .filter((e) => !oneOnOneIds || (!!e.assist?.dailyHabitId && oneOnOneIds?.includes(e.assist.dailyHabitId)));
  };
};

export function dtoToEvent(dto: EventDto): Event {
  const assist: AssistDetails | undefined = !!dto.assist
    ? {
        ...dto.assist,
        type: !!dto.assist.type ? AssistType.get(dto.assist.type) : undefined,
        status: dto.assist.status as unknown as AssistStatus,
        dailyHabitId: dto.assist.dailyHabitId || undefined,
        taskId: dto.assist.taskId || undefined,
        taskIndex: dto.assist.taskIndex || undefined,
      }
    : undefined;

  return {
    ...dto,
    assist,
    id: dto.id as unknown as string,
    key: dto.key as unknown as string,
    eventId: dto.eventId || "-",
    reclaimEventType: dto.reclaimEventType as unknown as ReclaimEventType,
    status: dto.status as unknown as EventStatus,
    projects: dto.projects?.map(dtoToProject),
    eventStart: strToDate(dto.eventStart) as Date,
    eventEnd: strToDate(dto.eventEnd) as Date,
    updated: strToDate(dto.updated),
    type: null,
    subType: null,
    meetingType: null,
    category: Category.get(dto.category as unknown as string) || PrimaryCategory.TeamMeeting,
    categoryOverride: Category.get(dto.categoryOverride as unknown as string),
    color: !!dto.color ? EventColor.get(dto.color) : EventColor.Auto,
    chunks:
      !!dto.eventEnd && !!dto.eventStart
        ? ((strToDate(dto.eventEnd) as Date).getTime() - (strToDate(dto.eventStart) as Date).getTime()) / 900000
        : 0,
    smurf: dto.smurf ? Smurf[dto.smurf] : undefined,
  };
}

export function eventToDto(event: Partial<Event>): EventDto {
  return removeEmpty({
    ...event,
    chunks: undefined,
    projects: event.projects?.map(projectToDto),
    eventStart: dateToStr(event.eventStart),
    eventEnd: dateToStr(event.eventEnd),
    updated: dateToStr(event.updated),
    category: event.category?.toJSON(),
    categoryOverride: event.categoryOverride?.toJSON(),
    color: (EventColor.Auto === event.color ? null : event.color?.toJSON()) as EventDto["color"],
  }) as EventDto;
}

const EventsSubscription = {
  subscriptionType: SubscriptionType.Events,
};

export class EventsDomain extends TransformDomain<Event, EventDto> {
  resource = "Event";
  cacheKey = "events";
  pk = "key";

  public deserialize = dtoToEvent;
  public serialize = eventToDto;

  watchWs$: Source<Event[]> = pipe(
    merge([
      this.ws.subscription$$(EventsSubscription),
      // Events can be included in assist payloads
      this.ws.subscription$$({ subscriptionType: SubscriptionType.AssistPlanned }),
      this.ws.subscription$$({ subscriptionType: SubscriptionType.AssistCompleted }),
    ]),
    filter((envelope) => !!envelope.data),
    map((envelope) => {
      return envelope.type === SubscriptionType.Events
        ? (envelope.data as EventDto[])
        : ((envelope.data as AssistCompleted).events as EventDto[]);
    }),
    buffer(interval(10)),
    map((buffered) => buffered.flat()),
    deserialize(this.deserialize)
  );

  watchAll$ = pipe(
    merge([this.upsert$, this.watchWs$]),
    map((items) => this.patchExpectedChanges(items))
  );

  watch$$ = (start?: Date, end?: Date) => {
    const subscription = {
      subscriptionType: SubscriptionType.Events,
      startTime: start,
      endTime: end,
    };

    return pipe(
      merge([
        this.upsert$,
        pipe(
          merge([
            this.ws.subscription$$(subscription),
            // Events can be included in assist payloads
            this.ws.subscription$$({ subscriptionType: SubscriptionType.AssistPlanned }),
            this.ws.subscription$$({ subscriptionType: SubscriptionType.AssistCompleted }),
          ]),
          filter((envelope) => !!envelope.data),
          map((envelope) => {
            return envelope.type === SubscriptionType.Events
              ? (envelope.data as EventDto[])
              : ((envelope.data as AssistCompleted).events as EventDto[]);
          }),
          buffer(interval(10)),
          map((buffered) => buffered.flat()),
          deserialize(this.deserialize)
        ),
      ]),
      map((items) => this.patchExpectedChanges(items))
    );
  };

  list$$ = (options: EventListOptions) => {
    return pipe(
      fromPromise(this.client.events.list(options)),
      map((items) => this.patchExpectedChanges(items))
    );
  };

  listAndWatch$$ = ({ start, end, statuses = [EventStatus.Published], ...options }: ListAndWatchOptions) => {
    return pipe(
      concat<Event[] | null>([this.list$$(options), this.watch$$(start, end)]),
      upsert((e) => this.getPk(e)),
      map(eventsQueryFilter({ start, end, statuses, ...options }))
    );
  };

  watchId$$ = (key: string) => {
    return pipe(
      concat([fromPromise(this.get(key).then((r) => (!!r ? [r] : []))), this.watchAll$]),
      map((items) => items?.find((i) => i.key === key))
    );
  };

  watchQuery$$ = ({ statuses = [EventStatus.Published], ...options }: WatchQueryOptions) => {
    return pipe(
      this.watch$$(options.start, options.end),
      upsert((e) => this.getPk(e)),
      map(eventsQueryFilter({ statuses, ...options }))
    );
  };

  list = this.deserializeResponse(
    ({ start, end, type, include, sourceDetails, habitIds, oneOnOneIds, calendarIds }: EventListOptions) => {
      return this.api.events.query({
        start: start ? format(start, "yyyy-MM-dd") : undefined,
        end: end ? format(end, "yyyy-MM-dd") : undefined,
        type: !!type ? type.map((t) => t.toJSON() as EventTypeDto) : undefined,
        includeProjects: include as unknown as ProjectIncludeDto,
        sourceDetails: sourceDetails,
        habitIds,
        calendarIds,
        recurringOneOnOneIds: oneOnOneIds,
      });
    }
  );

  listPersonal = this.deserializeResponse((start?: Date, end?: Date, limit?: number) => {
    return this.api.events.getPersonal1({
      start: start ? format(start, "yyyy-MM-dd") : undefined,
      end: end ? format(end, "yyyy-MM-dd") : undefined,
      limit,
    });
  });

  get = this.deserializeResponse((eventId: string, calendarId?: number, sourceDetails?: boolean) => {
    const query = { sourceDetails };
    return undefined !== calendarId
      ? this.api.events.getForCalendar(calendarId, eventId, query)
      : this.api.events.get1(eventId, query);
  });

  adjustTravelTime = (
    calendarId: number,
    eventId: string,
    type: typeof AssistType.PreTravel | typeof AssistType.PostTravel,
    duration: number
  ) => {
    const notificationKey = this.generateUid("adjustTravelTime", `${calendarId}/${eventId}`);

    this.addNotificationKey(notificationKey);

    return this.api.events
      .adjustTravelTime(calendarId, eventId, type.key, { duration, notificationKey }, {})
      .then((res) => {
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
        return res;
      })
      .catch((reason) => {
        console.warn("Request failed, clearing notification key", notificationKey, reason);
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Failed);
        throw reason;
      });
  };

  adjustConfBuffer = (calendarId: number, eventId: string, duration: number) => {
    const notificationKey = this.generateUid("adjustConfBuffer", `${calendarId}/${eventId}`);

    this.addNotificationKey(notificationKey);

    return this.api.events
      .adjustConferenceBuffer(calendarId, eventId, { duration, notificationKey }, {})
      .then((res) => {
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
        return res;
      })
      .catch((reason) => {
        console.warn("Request failed, clearing notification key", notificationKey, reason);
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Failed);
        throw reason;
      });
  };
}

export const getNextUpcomingEvent = (events: Event[]): Event | null => {
  let next: Event | null = null;
  const now = new Date();

  if (!!events.length) {
    events.forEach((i) => {
      if (
        (!next && i.eventEnd > now) ||
        (!!i.eventEnd && i.eventEnd > now && !!i.eventStart && !!next?.eventStart && i.eventStart < next.eventStart)
      ) {
        next = i;
      }
    });
  }

  return next;
};
